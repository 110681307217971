import {TOKEN_BURNT_ORANGE, TOKEN_FOREST_GREEN, TOKEN_PURPLE, TOKEN_SILVER_GRAY, TOKEN_SPRING_GREEN, TOKEN_SUCCES_GREEN, TOKEN_SUNSET_ORANGE, TOKEN_UI_BLUE, TOKEN_SIGNAL_YELLOW, CHART_COLOR_TOKENS} from './tokens'

const generateColor = (colorToken: ColorToken[]) => {
    return {
        25: colorToken[0].hex,
        50: colorToken[1].hex,
        100: colorToken[2].hex,
        200: colorToken[3].hex,
        300: colorToken[4].hex,
        400: colorToken[5].hex,
        500: colorToken[6].hex,
        600: colorToken[7].hex,
        700: colorToken[8].hex,
        800: colorToken[9].hex,
        900: colorToken[10].hex
    }
}

export const CHART_COLORS = CHART_COLOR_TOKENS.map((color) => color.hex)

export const SINGLE_CATEGORY_COLORS = [
    '#142459',
    '#176BA0',
    '#19AADE',
    '#1AC9E6',
    '#1DE4BD',
    '#6DFDD2',
    '#29066B',
    '#7D3AC1',
    '#AF4BCE',
    '#DB4CB2',
    '#EB548C',
    '#EA7369',
    '#FDA58F',
    '#FCEAE6',
    '#820401',
    '#DE542C',
    '#EE9A3A',
    '#E7E34E',
]

export const COMPANY_COLORS = [
    '#142459',
    '#7D3AC1',
    '#EB548C',
    '#820401',
    '#DE542C',
    '#EE9A3A',
    '#19AADE',
    '#E7E34E',
]

interface ColorToken {
    hex: string,
    rgb: string,
    token: string
}

export const PURPLE = generateColor(TOKEN_PURPLE)
export const SPRING_GREEN = generateColor(TOKEN_SPRING_GREEN)
export const FOREST_GREEN = generateColor(TOKEN_FOREST_GREEN)
export const SUNSET_ORANGE = generateColor(TOKEN_SUNSET_ORANGE)
export const BURNT_ORANGE = generateColor(TOKEN_BURNT_ORANGE)
export const SILVER_GRAY = generateColor(TOKEN_SILVER_GRAY)
export const UI_BLUE = generateColor(TOKEN_UI_BLUE)
export const SUCCESS_GREEN = generateColor(TOKEN_SUCCES_GREEN)
export const SIGNAL_YELLOW = generateColor(TOKEN_SIGNAL_YELLOW)

export const COLORS = {
    FUND: FOREST_GREEN[500],
    FUND_LIGHT: FOREST_GREEN[500],
    FUND_SELECTED: FOREST_GREEN[600],
    MID: '#FFFFFF40',
    DEPOSIT: SPRING_GREEN[500],
    DEPOSIT_LIGHT: SPRING_GREEN[500],
    DEPOSIT_SELECTED: SPRING_GREEN[600],

    PURPLE: '#4D2A98',
    SILVER_GRAY: '#6F7271',
    FOREST_GREEN: '#21362C',
}
